.LanguageSelector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    right: 1rem;
    top: 1.7rem;
}



.SelectedLanguage {
    width: 2rem;
    cursor: pointer;
}

.LanguageOption {
    width: 2rem;
    cursor: pointer;
}


@media (max-width: 700px) {

    .LanguageSelector {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        position: absolute;
        right: 1rem;
        top: 1.3rem;
    }

}