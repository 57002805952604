.DividerLine {
    width: 100%;
    height: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.DividerLine>div {
    width: 90%;
    height: 1px;
    border-radius: 2rem;
    background-color: gray;
    justify-content: center;
}