.IntroductionContainer {
    width: 100%;
    padding: 1rem;
    padding-left: 3rem;
    padding-top: 3rem;
    display: grid;
    grid-template-columns: 50% 45%;
    flex-direction: row;
    gap: 5%;
    height: 100vh;
}

.Introduction>span {
    font-family: var(--Fonte);
    display: block;
    margin: 0;
    padding: 0;
}

.IntroductionGreeting {
    color: var(--Secondary);
    font-weight: 500;
}

.IntroductionName {
    color: var(--Complementary);
    font-size: 5rem;
    padding: 0;
    margin: 0;
    font-weight: 500;
}


.IntroductionText {
    color: var(--Complementary);
}


.IntroductionLengthContainer {
    display: flex;
    flex-direction: column;
    color: var(--Complementary);

    font-family: var(--Fonte);
    margin-bottom: 1rem;
}

.IntroductionLengthDesc {
    display: flex;
    flex-direction: row;
    gap: 10rem;
    margin-left: 10rem;

}

.IntroductionLengthDesc>span {
    font-size: .6;
    font-family: var(--Fonte);
}

.IntroductionLengthOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.IntroductionLengthOptionsDots {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.IntroductionLengthOption {
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--Secondary);
    border-radius: 50%;
    cursor: pointer;
}

.IntroductionLengthOptionActive {
    background-color: var(--Secondary);
}


@media (max-width: 900px) {

    .IntroductionLengthOptions { 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }


    .IntroductionContainer {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        min-height: 100vh;
    }

    .IntroductionGreeting {
        font-size: .9rem;
    }

    .IntroductionName {
        font-size: 3.5rem;

    }

    .IntroductionText {
        font-size: .9rem;
    }
}