.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    display: none;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.scroll-to-down-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 9999;
    display: none;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}


.scroll-to-top-button>svg {
    color: var(--Secondary);
    font-size: 2rem;

}

.scroll-to-down-button>svg {
    color: var(--Secondary);
    font-size: 2rem;

}

.bounce {
    animation: bounce 1.5s ease infinite;

}

@keyframes bounce {
    70% {
        transform: translateY(0%);
    }

    80% {
        transform: translateY(-15%);
    }

    90% {
        transform: translateY(0%);
    }

    95% {
        transform: translateY(-7%);
    }

    97% {
        transform: translateY(0%);
    }

    99% {
        transform: translateY(-3%);
    }

    100% {
        transform: translateY(0);
    }
}


.scroll-to-top-button.visible {
    display: flex;
}

.scroll-to-down-button.visible {
    display: none;
}

@media (max-width: 900px) {
    .scroll-to-down-button {
        display: flex;
    }

    .scroll-to-down-button.visible {
        display: flex;
    }


}