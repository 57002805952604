.tagcloud--item {
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 600;
    user-select: none;
}


.TagsCloudContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

}

.tagcloudMobile {
    display: none;
}


.tag-cloud{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media (max-width: 900px) {

    .tagcloud {
        display: none !important;
    }

    .tagcloudMobile{
        display: block !important;
    }

}