@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mitr:wght@200;700&display=swap');


:root {
  --Primary: #0A192F;
  --PrimaryLight: #102646;
  --PrimaryTransparent: rgba(10, 25, 47, .1);
  --Secondary: #D8A31A;
  --SecondaryTransparent: rgba(216, 163, 26, .15);
  --Complementary: #E3F0FF;
  --Fonte: 'kanit', 'Sans Serif';
}



/* ROXO COM AMARELO E VERMELHO
:root {
  --Primary: #4D455D;
  --PrimaryTransparent: rgba(77, 69, 93, .1);
  --Secondary: #E96479;
  --SecondaryTransparent: rgba(233, 100, 121, .1);
  --Complementary: #F5E9CF;
  --Fonte: 'kanit', 'Sans Serif';
}

/*
:root {
  --Primary: #F5EAEA;
  --PrimaryTransparent: rgba(245, 234, 234, .1);
  --Secondary: #FFB84C;
  --SecondaryTransparent: rgba(255, 184, 76, .15);
  --Complementary: #F16767;
  --Fonte: 'kanit', 'Sans Serif';
}
*/

body {
  margin: 0;
  padding: 0;
  background-color: var(--Primary) !important;
  overflow-y: scroll;
}


body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}


/* BOTÃO PADRÂO */

.DefaultButton {
  font-family: var(--Fonte);
  color: var(--Secondary);
  border: 1px solid var(--Secondary);
  background-color: transparent;
  border-radius: .3rem;
  padding: .5rem 1rem;
  margin-left: 2rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
}

.DefaultButton>a {
  font-family: var(--Fonte);
  color: var(--Secondary);
  text-decoration: none;
}

.DefaultButton>a:hover {
  font-family: var(--Fonte);
  color: var(--Secondary);
  text-decoration: none;
}

.DefaultButton:hover {
  background-color: var(--SecondaryTransparent);
}

/* BOTÃO PADRÂO */