.Academic {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    margin-bottom: 5rem;
    padding-top: 5.5rem;
}


.AcademicTitle {
    font-size: 3rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-align: center;
}



.AcademicDesc {
    font-size: 1.1rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}










.AcademicItem {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 20% 75%;
    gap: 5%;
}

.AcademicItemPhoto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.AcademicItemDescContainer {
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
}



.AcademicItemPhoto>img {
    border-radius: 50%;
    width: 90% !important;
}

.AcademicItemRole {
    font-size: 1.1rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    display: grid;
    grid-template-columns: 2% 90%;
    align-items: center;
    justify-content: start;
    font-weight: 500;
    margin-bottom: .5rem;
}

.AcademicItemCompanyName {

    margin-top: .5rem;
}

.AcademicItemCompanyName>a {
    font-size: 1.1rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 600;
    text-decoration: none;
}

.AcademicItemPeriod,
.AcademicItemLocation {
    display: grid;
    grid-template-columns: 2% 90%;
    align-items: center;
    justify-content: start;
    font-size: .9rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
}

.AcademicItemDesc {
    font-size: 1rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
    margin-top: 1.5rem;
    text-align: justify;
}





.AcademicTags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: .4rem;
    margin-top: 1rem;
    user-select: none;
}

.AcademicTag {
    font-family: var(--Fonte);
    font-size: .7rem;
    color: var(--Complementary);
    padding: .3rem .5rem;
    background-color: var(--Secondary);
    border-radius: .3rem;
    user-select: none;
}




@media (max-width: 900px) {
    .AcademicItem {
        display: flex;
        flex-direction: column;
    }

    .AcademicItemDescContainer {
        justify-content: center;
    }

    .AcademicTitle {
        font-size: 2rem;
        text-align: left;

    }



    .AcademicItemRole,
    .AcademicItemPeriod,
    .AcademicItemLocation {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: .4rem;
    }


    .AcademicItemPhoto {
        display: grid;
        grid-template-columns: 30% 65%;
        gap: 5%;
        margin-bottom: .5rem;
    }

    .AcademicItemPhoto>img {
        width: 90% !important;
    }

    .AcademicItemDescContainer {
        padding: .5rem;
    }
}