.ChatGPT {
    padding: 2rem 1rem;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 1rem;
}

.ChatGPTTile {
    font-family: var(--Fonte);
    color: var(--Complementary);

}

.ChatGPTTile>svg {
    color: var(--Secondary);
}


.ChatGPTSearchForm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;

}

.ChatGPTSearch {
    font-family: var(--Fonte);
    color: var(--Secondary);
    border: 1px solid var(--Secondary);
    background-color: transparent;
    border-radius: .3rem;
    padding: .5rem 1rem;
    font-weight: 500;
    outline: none;
    flex-grow: 1;
    font-size: .9rem;
}

.ChatGPTSearch::placeholder {
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-weight: 400;
    font-size: .9rem;
}


.ChatGPTSearchButton {
    margin: 0;
    font-size: .9rem;
}




.ChatGPTAnswer {
    color: var(--Complementary);
    font-size: .8rem;
    cursor: pointer;
    font-family: var(--Fonte);
    text-align: justify;
}

.ChatGPTAnswerCentered {
    color: var(--Complementary);
    font-size: .8rem;
    cursor: pointer;
    font-family: var(--Fonte);
    text-align: justify;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}


.ChatGPTAnswerIntroduction {
    flex-grow: 1;
}



@media (max-width: 900px) {
    .ChatGPT {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 1rem;

    }
}