.Projects {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    padding-top: 5.5rem;
}

.Project {
    display: grid;
    grid-template-columns: 45% 55%;
    justify-content: center;
    margin-bottom: 4rem;
}

.ProjectsTitle {
    font-size: 3rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-align: center;
}

.ProjectsDesc {
    font-size: 1.1rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}


.Divider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Divider>div {
    width: 3rem;
    height: .4rem;
    border-radius: 2rem;
    background-color: var(--Secondary);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ProjectImage {
    display: flex;
    justify-content: center;
    align-items: center;
}


.ProjectImage>img {
    width: 90%;
}


.PersonalProject {
    width: 70% !important;
    border-radius: 1rem;
}


.ProjectInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ProjectName {

    margin-bottom: 2rem;
}

.ProjectName>a {
    font-size: 2rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-decoration: none;
}


.ProjectDesc {
    font-size: .9rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
}


.ProjectLink {
    margin-top: 1.5rem;
}

.ProjectLink>.DefaultButton {
    margin: 0;
}


.ProjectTags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: .4rem;
    margin-top: 1rem;
}

.ProjectTag {
    font-family: var(--Fonte);
    font-size: .7rem;
    color: var(--Complementary);
    padding: .3rem .5rem;
    background-color: var(--Secondary);
    border-radius: .3rem;
    user-select: none;
}

@media (max-width: 900px) {



    .Divider {
        display: none;
    }

    .ProjectTags {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: .4rem;
        margin-top: 1rem;
        user-select: none;
    }

    .Project {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 5rem;
    }

    .ProjectInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ProjectDesc {
        text-align: center;
    }

    .PersonalProject {
        margin-bottom: 1rem;
    }


    .ProjectsDesc {
        margin-bottom: 5rem;
    }

    .ProjectName {
        margin-bottom: 1rem;
        text-align: center;
    }

}








.Teste {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem 5rem;
    height: 100% !important;
}