.Header {
    padding: 1rem;
    padding-left: 3rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

}



.NavBarHeader {
    position: sticky !important;
    top: 0;
    background-color: var(--Primary);
    opacity: 0.96;
    z-index: 100;
}



html {
    scroll-behavior: smooth;
}


/* ICONS */

.HeaderIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    flex-grow: 1;
}

.HeaderIcons>a>svg {
    color: var(--Complementary);
    font-size: 2rem;
    cursor: pointer;
}





.HeaderIcons>a>svg:hover {
    transform: translateY(.1rem);
    color: var(--Secondary);
}











/* LINKS */

.HeaderLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    margin-right: 4rem;
}

.HeaderLink {
    cursor: pointer;
}

.HeaderLink>span:first-child {
    font-family: var(--Fonte);
    color: var(--Complementary);
    margin-right: .3rem;
    font-weight: 400;
}

.HeaderLink>span:last-child {
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-weight: 500;
}

.HeaderLink>span:last-child:hover {
    color: var(--Complementary);
}

.BlogLink {
    font-family: var(--Fonte);
    color: var(--Secondary) !important;
    font-weight: 500;
    text-decoration: none;
}

.BlogLink:hover {
    color: var(--Complementary) !important;
}




.NavBarHamburguer>svg {
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-weight: 500;
    font-size: 2.5rem;
}



.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none !important;
    box-shadow: none !important;
}


@media (max-width: 1060px) {
    .HeaderIcons {
        gap: 1rem;
    }

    .HeaderIcons>a>svg {
        font-size: 1.5rem;
    }

    .HeaderLinks {
        gap: 1.8rem;
    }

    .HeaderLink>span:first-child {
        font-size: .9rem;
    }

    .HeaderLink>span:last-child {
        font-size: .9rem;
    }
}


@media (max-width: 900px) {



    .HeaderIcons {
        gap: 1rem;
    }


    .HeaderIcons>a>svg {
        font-size: 1.3rem;
    }

    .HeaderLinks {
        gap: 1.5rem;
    }



}




@media (max-width: 760px) {

    .NavOpened {
        background-color: var(--PrimaryLight) !important;
    }

    .Header {
        padding: 1em;
        display: grid;
        grid-template-columns: 30% 70%;
        align-items: stretch;
        gap: 4rem;
        padding-top: 2rem;
    }

    .ResumeButton {
        margin-left: 0 !important;
    }



    .HeaderIcons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 2rem;
        margin-right: 1rem;
    }


    .HeaderIcons>a>svg {
        font-size: 1.5rem;
    }

    .HeaderLinks {
        gap: 1.5rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;


    }

    .HeaderLinks>button {
        margin: 0;
    }

}