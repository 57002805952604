.CertificationsContainer {
    padding: 2rem;
    
}

.CertificationsTitle {
    font-size: 3rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-align: center;
}

.CertificationsListContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: .5rem;
    flex-grow: 1;
    margin-top: 1rem; 
}

.CertificationItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 1rem;
    background-color: var(--Complementary);
    border-radius: .5rem;
    flex-grow: 1;
    font-family: var(--Fonte);
    color: var(--Secondary);
    border: 1px solid var(--Secondary);
    background-color: transparent;
    cursor: pointer;
    outline: none;
    font-size: 1rem;


}

.CertificationName{
    user-select: none;
}

.CertificationIcon>svg {
    font-size: 1rem;
    color: var(--Secondary) !important;
}

.CertificationItem:hover {
    background-color: var(--SecondaryTransparent);
}






@media (max-width: 900px) {
    .CertificationsContainer {
        padding: 1rem;
    }

    .CertificationsTitle{
        margin-bottom: 2rem;
        text-align: left;
    }

    .CertificationItem {
        align-items: center;
        padding: 1.5rem .7rem;
        font-size: .7rem;
        gap: .3rem;
        user-select: none;

    }
}