.Dashboard {
  padding: 2rem;
}

.UsoHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: var(--Fonte);
  margin-top: 0.3rem;
}

.UsoHeader > span {
  font-family: var(--Fonte);
  font-size: 1rem;
  color: var(--Secondary);
}

.UsosDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.Uso {
  display: flex;
  flex-direction: row;
}

.UsoValue {
  font-family: var(--Fonte);
  font-size: 0.7rem;
  color: var(--Secondary);
}

.Mudou {
  font-family: var(--Fonte);
  font-size: 0.7rem;
  color: var(--Secondary);
}

.NãoMudou {
  font-family: var(--Fonte);
  font-size: 0.7rem;
  color: var(--Complementary);
}

.UsoName {
  font-family: var(--Fonte);
  font-size: 0.7rem;
  margin-right: 0.3rem;
  color: var(--Complementary);
}

.Dashboard > h1 {
  font-family: var(--Fonte);
  color: var(--Complementary);
  margin-bottom: 1rem;
}

.DashboardInput{
  font-family: var(--Fonte);
  color: var(--Secondary);
  border: 1px solid var(--Secondary);
  background-color: transparent;
  border-radius: .3rem;
  padding: .4rem 1rem;
  font-weight: 500;
  outline: none;
  flex-grow: 1;
  font-size: .6rem;
  margin-bottom: 2rem;
}

.accordion-body {
  background-color: var(--Primary) !important;
  border: none !important;
}

.accordion-header {
  background-color: var(--Primary) !important;
}

.accordion-header > button {
  background-color: var(--Secondary) !important;
  color: var(--Complementary) !important;
  font-family: var(--Fonte) !important;
}

.accordion-header > button::after {
  color: var(--Complementary) !important;
  font-family: var(--Fonte) !important;
}

.accordion-item {
  background-color: var(--Primary) !important;
  border: 1px solid var(--Secondary) !important;
}

.DeleteButton {
  font-family: var(--Fonte);
  color: var(--Secondary);
  border: 1px solid var(--Secondary);
  background-color: transparent;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  font-weight: 500;
  cursor: pointer;
  outline: none;
}

.DeleteButton > a {
  font-family: var(--Fonte);
  color: var(--Secondary);
  text-decoration: none;
}

.DeleteButton > a:hover {
  font-family: var(--Fonte);
  color: var(--Secondary);
  text-decoration: none;
}

.DeleteButton:hover {
  background-color: var(--SecondaryTransparent);
}

.UsosAccordionHeader {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.HeadTag {
  background-color: var(--Primary);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin-left: 2rem;
  border-radius: 5rem;
}

.ButtonsDiv {
  display: flex;
  gap: 1rem;
  justify-content: end;
  align-items: center;
}

.TagDiv {
  margin-top: 1rem;
}

@media (max-width: 900px) {
  .Dashboard {
    padding: 0.2rem;
  }

  .UsoHeader > span {
    font-size: 0.8rem;
  }

  .UsoValue {
    font-size: 0.5rem;
  }

  .Mudou {
    font-size: 0.5rem;
  }

  .NãoMudou {
    font-size: 0.5rem;
    color: var(--Complementary);
  }

  .UsoName {
    font-size: 0.5rem;
    margin-right: 0.3rem;
    color: var(--Complementary);
  }
}
