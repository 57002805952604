.ContactContainer {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7rem;
    padding-top: 5rem;
}

.ContactTitle {
    font-size: 3rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-align: center;
}



.ContactForm {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

}


.ContactInputGroup {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: .5rem;
}

.ContactInputGroup>label {
    font-family: var(--Fonte);
    color: var(--Complementary);
}


.Contact {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
}

.ContactInputGroup>input,
.ContactInputGroup>textarea {
    font-family: var(--Fonte);
    color: var(--Secondary);
    border: 1px solid var(--Secondary);
    background-color: transparent;
    border-radius: .3rem;
    padding: .6rem 1.2rem;
    font-weight: 500;
    outline: none;
    flex-grow: 1;
    font-size: .9rem;
}

.ContactInputGroup>textarea::placeholder,
.ContactInputGroup>input::placeholder {
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-weight: 400;
    font-size: .9rem;
}

.ContactInputGroup>textarea {
    min-height: 7rem;
}

.SocialMedia {
    padding: 2rem 6rem;
    display: grid;
    grid-template-columns: 50% 50%;
    flex-grow: 1;
    gap: 1rem;
    flex-wrap: wrap;

}


.SocialMediaItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    gap: .5rem;
}

.SocialMediaItem>img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.SocialMediaItem>span {
    font-family: var(--Fonte);
    color: var(--Complementary);
}

.SocialMediaItem>a {
    font-family: var(--Fonte);
    color: var(--Complementary);
    text-decoration: none;
}

.SocialMediaItem>a:hover {
    font-family: var(--Fonte);
    color: var(--Secondary);
    text-decoration: none;
}








.SendEmailButton {
    font-family: var(--Fonte);
    color: var(--Complementary);
    border: 1px solid var(--Secondary);

    border-radius: .3rem;
    padding: .5rem 1rem;
    margin-top: .5rem;
    font-weight: 500;

    outline: none;
}

.ButtonFilled {
    background-color: var(--Secondary);
    cursor: pointer;
}

.ButtonNotFilled {
    background-color: var(--Secondary);
    cursor: not-allowed;
    opacity: .5;
}

.SendEmailButton>a {
    font-family: var(--Fonte);
    color: var(--Secondary);
    text-decoration: none;
}

.SendEmailButton>a:hover {
    font-family: var(--Fonte);
    color: var(--Secondary);
    text-decoration: none;
}

.SendEmailButton:hover {
    background-color: var(--Secondary);
}

.Sent {
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.Sent>div {
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-size: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.Sent>div>svg {
    margin-right: 1rem;
    color: var(--Complementary);
}

@media (max-width: 900px) {

    .SendEmailButton {
        width: 100%;
    }

    .ContactContainer {
        padding: 1rem;
    }

    .ContactTitle {
        font-size: 2rem;
        text-align: left;
        margin-bottom: 1rem;
    }


    .Contact {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .ContactForm {
        width: 100%;
        margin-bottom: 2rem;
    }

    .Sent {
        margin-bottom: 2rem;
    }


    .SocialMedia {
        padding: 0;
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 1rem;
        flex-wrap: wrap;
        padding-bottom: 3rem;

    }


}