.About {
    display: grid;
    grid-template-columns: 45% 55%;
    margin-top: .5rem;

}

.AboutContainer {
    display: flex;
    flex-direction: column;
    padding-left: 3rem;
    margin-top: 10rem;
    padding-top: 1rem;

    padding-top: 5.5rem;
}

.AboutText {
    color: var(--Complementary);
    font-family: var(--Fonte);

}

.AboutTitle {
    font-size: 3rem;
    font-family: var(--Fonte);
    color: var(--Secondary);
    text-align: center;
}


.SoftSkills {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
}


.AboutDescription {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-align: justify;
}


.AboutName {
    font-size: 1.5rem;
    font-family: var(--Fonte);
    color: var(--Secondary);
    font-weight: 600;

}

.SkillsTitle {
    font-size: 1.5rem;
    font-family: var(--Fonte);
    color: var(--Complementary);
    font-weight: 600;
    text-align: center;
}

@media (max-width: 900px) {

    .AboutContainer {
        padding: 1rem;
    }

    .About {
        display: flex;
        flex-direction: column;

    }

    .AboutTitle {
        font-size: 2rem;
        text-align: left;
    }

    .SoftSkills {
        font-size: 1.5rem;
        min-height: 2rem;
        height: 2rem;
        text-align: center;
    }

    .AboutContainer {

        height: auto;

    }

    .AboutContainer {
     
        padding-top: 1rem;
    }

}