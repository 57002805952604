.CoursesContainer {
    padding: 2rem;

}

.CoursesTitle {
    font-size: 3rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-align: center;
}


.CoursesListContainer {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: .6rem;
    margin-top: 1rem;
    flex-grow: 1;
}


.Course {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    border: 1px solid var(--Secondary);
    background-color: transparent;
    padding: 1rem 1rem;
    border-radius: .5rem;
    cursor: pointer;
    user-select: none;

}

.Course:hover {
    background-color: var(--SecondaryTransparent);
}


.Institution {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    user-select: none;

}

.Institution>img {
    width: 80% !important;
    border-radius: 50%;
    width: 5rem !important;
}


.InstitutionName {
    font-family: var(--Fonte);
    color: var(--Secondary);
    text-align: center;
}

.CourseName {
    font-family: var(--Fonte);
    color: var(--Secondary);
}

.CourseDatails {
    font-family: var(--Fonte);
    color: var(--Complementary);
}

.CourseDatails {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 1rem;
}

.CourseInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.CenterInstitutionName {
    display: flex;

    align-items: flex-start !important;
}

@media (max-width: 900px) {
    .CoursesContainer {
        padding: 1rem;
    }

    .CoursesTitle {
        font-size: 2rem;
        text-align: left;
        margin-bottom: 1rem;
    }

    .Institution>img {
        border-radius: 50%;
        width: 3rem !important;
    }

    .InstitutionName {
        font-size: .8rem;
    }

    .CourseName {
        font-size: .7rem;
    }

    .CourseDatails {
        font-size: .7rem;
    }



}