.Experience {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    margin-bottom: 5rem;
    padding-top: 5.5rem;
}


.ExperienceTitle {
    font-size: 3rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 500;
    text-align: center;
}



.ExperienceDesc {
    font-size: 1.1rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
}










.ExperienceItem {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 20% 75%;
    gap: 5%;
    margin-bottom: 2rem;
}

.ExperienceItemPhoto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ExperienceItemDescContainer {
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
}

.ExperienceItemDescContainer>div:nth-child(2) {
    margin-bottom: 1rem;
}

.ExperienceItemPhoto>img {
    border-radius: 50%;
    width: 90% !important;
}

.ExperienceItemRole {
    font-size: 1.1rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    display: grid;
    grid-template-columns: 2% 90%;
    align-items: center;
    justify-content: start;
    font-weight: 500;
}

.ExperienceItemCompanyName {

    margin-top: .5rem;
}

.ExperienceItemCompanyName>a {
    font-size: 1.1rem;
    color: var(--Secondary);
    font-family: var(--Fonte);
    font-weight: 600;
    text-decoration: none;
}

.ExperienceItemPeriod,
.ExperienceItemLocation {
    display: grid;
    grid-template-columns: 2% 90%;
    align-items: center;
    justify-content: start;
    font-size: .9rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
}

.ExperienceItemDesc {
    font-size: 1rem;
    color: var(--Complementary);
    font-family: var(--Fonte);
    margin-top: 1.5rem;
    text-align: justify;
}





.ExperienceTags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: .4rem;
    margin-top: 1rem;
    user-select: none;
}

.ExperienceTag {
    font-family: var(--Fonte);
    font-size: .7rem;
    color: var(--Complementary);
    padding: .3rem .5rem;
    background-color: var(--Secondary);
    border-radius: .3rem;
    user-select: none;
}




@media (max-width: 900px) {
    .ExperienceItem {
        display: flex;
        flex-direction: column;
    }

    .ExperienceItemDescContainer {
        justify-content: center;
    }


    .ExperienceTitle {
        font-size: 2rem;
        text-align: left;
    }



    .ExperienceItemRole,
    .ExperienceItemPeriod,
    .ExperienceItemLocation {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: .3rem;
    }


    .ExperienceItemPhoto {
        display: grid;
        grid-template-columns: 30% 65%;
        gap: 5%;
        margin-bottom: .5rem;
    }

    .ExperienceItemPhoto>img {
        width: 90% !important;
    }

    .ExperienceItemDescContainer {
        padding: .5rem;
    }
}